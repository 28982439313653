import React, { Fragment } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import {
  FetchProductsCacheKeys,
  OnSelectedProductsSidebarCloseAction
} from '../../../../../../products/productsTypes';

import {
  UpdateProductsSetCacheAction,
  FetchProductsSetsCacheKeys
} from '../../../../../../productsSets/productsSetsTypes';

import { SelectedProductsListItemDeleteButton } from '../../../SelectedProductsListItem/components/SelectedProductsListItemDeleteButton';

import { DownloadSelectedProductButton } from '../../../../buttons/DownloadSelectedProductButton';

import { SelectedProductsListItemLightboxFavoriteButton } from '../SelectedProductsListItemLightboxFavoriteButton';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import {
  SelectedProductsListItemLightboxBottomButtonsSelectedProduct,
  SelectedProductsListItemLightboxBottomButtonsProductsSet
} from './SelectedProductsListItemLightboxBottomButtons.types';

import { SelectedProductsPermissions } from '../../../../../selectedProductsConstants';

import { words } from '../../../../../../../locales/keys';

interface SelectedProductsListItemLightboxBottomButtonsProps {
  selectedProduct: SelectedProductsListItemLightboxBottomButtonsSelectedProduct;
  productsSet: SelectedProductsListItemLightboxBottomButtonsProductsSet | null;
  productsCacheKeys?: FetchProductsCacheKeys;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
  onSelectedProductsSidebarClose: OnSelectedProductsSidebarCloseAction;
  updateProductsSetCache: UpdateProductsSetCacheAction<SelectedProductsListItemLightboxBottomButtonsProductsSet>;
}

function SelectedProductsListItemLightboxBottomButtons({
  selectedProduct,
  productsSet,
  productsCacheKeys,
  productsSetCacheKeys,
  onSelectedProductsSidebarClose,
  updateProductsSetCache
}: SelectedProductsListItemLightboxBottomButtonsProps) {
  return (
    <Fragment>
      <SelectedProductsListItemDeleteButton
        selectedProduct={selectedProduct}
        productsSet={productsSet}
        productsSetCacheKeys={productsSetCacheKeys}
        className="py-2 font-medium inline-flex space-x-1 items-center rounded-md px-4 text-gray-800 hover:bg-gray-300 bg-gray-200 px-4 focus:ring-white focus:ring-offset-black"
        i18nText={words.remove}
        onSelectedProductsSidebarClose={onSelectedProductsSidebarClose}
        updateProductsSetCache={updateProductsSetCache}
      />
      <CheckPermissions
        action={
          SelectedProductsPermissions.READ_SELECTED_PRODUCT_FAVORITE_BUTTON
        }
      >
        <SelectedProductsListItemLightboxFavoriteButton
          selectedProduct={selectedProduct}
          productsCacheKeys={productsCacheKeys}
          productsSetCacheKeys={productsSetCacheKeys}
        />
      </CheckPermissions>
      <CheckPermissions
        action={
          SelectedProductsPermissions.READ_SELECTED_PRODUCTS_DOWNLOAD_BUTTON
        }
      >
        <DownloadSelectedProductButton
          selectedProductUuid={selectedProduct.uuid}
          className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-white focus:ring-offset-0 hover:bg-white hover:bg-opacity-10 text-gray-300 hover:text-white"
          icon={IconsEnum.DOWNLOAD_OUTLINE}
          iconClassName="h-6 w-6"
          tooltipSingleton
          tooltipI18nText={words.download}
        />
      </CheckPermissions>
    </Fragment>
  );
}

export default SelectedProductsListItemLightboxBottomButtons;
