import { useCallback } from 'react';
import { ClientError } from 'graphql-request';
import { useMutation, useQueryClient } from 'react-query';

import { UpdateProductsSetCacheAction } from '../../productsSetsTypes';

import {
  EmptyProductsSetInput,
  EmptyProductsSetError,
  EmptyProductsSetResponse,
  action,
  EMPTY_PRODUCTS_SET_QUERY,
  EmptyProductsSetQueryResponse
} from '../../queries/emptyProductsSet.query';

import { updateItem } from '../../../common/hooks/base/reactQuery/baseActions/updateItem';

import { parseRequestError } from '../../../../utils/parseRequestError';

interface EmptyProductsSetContext {
  rollback?: () => void;
}

interface EmptyProductsSetOptions {
  itemCacheKey: string;
  updateProductsSetCache: UpdateProductsSetCacheAction<EmptyProductsSetQueryResponse>;
}

function useEmptyProductsSet({
  itemCacheKey,
  updateProductsSetCache
}: EmptyProductsSetOptions) {
  const queryClient = useQueryClient();

  const handleOptimisticUpdate = useCallback<() => null | (() => void)>(() => {
    return updateProductsSetCache({
      updateFunction: (prevProductSet) => ({
        ...prevProductSet,
        selectedProducts: []
      })
    });
  }, [updateProductsSetCache]);

  const { error, isLoading, mutateAsync, reset } = useMutation<
    EmptyProductsSetResponse,
    EmptyProductsSetError | ClientError | Error,
    EmptyProductsSetInput,
    EmptyProductsSetContext
  >(
    (queryInput) =>
      updateItem<EmptyProductsSetInput, EmptyProductsSetResponse>({
        query: EMPTY_PRODUCTS_SET_QUERY,
        queryInput,
        action
      }),
    {
      onMutate: () => {
        const rollback = handleOptimisticUpdate() || undefined;

        return { rollback };
      },
      onError: (error, variables, context) => {
        context?.rollback?.();
      },
      onSettled: () => {
        if (itemCacheKey) {
          queryClient.invalidateQueries(itemCacheKey);
        }
      }
    }
  );

  return {
    emptyProductsSetError: error,
    emptyProductsSetLoading: isLoading,
    emptyProductsSetErrorMessage: parseRequestError(error),
    emptyProductsSet: mutateAsync,
    emptyProductsSetReset: reset
  };
}

export default useEmptyProductsSet;
