import { IsDisabled } from '../../../../../types';

import {
  ChangeProductsFiltersFunc,
  FetchProductsFilters,
  ProductsCustomExitNavPath,
  ProductsFiltersNavBasePath,
  ProductsI18nCustomBaseTitle
} from '../../../productsTypes';

import { useThreeDStockProductsCategories } from '../../../../stock/hooks/useThreeDStockProductsCategories';

import { ProductsIndexPageCategoriesNavBody } from './components/ProductsIndexPageCategoriesNavBody';

import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';

interface ProductsIndexPageCategoriesNavProps {
  filtersNavBasePath?: ProductsFiltersNavBasePath;
  customExitNavPath?: ProductsCustomExitNavPath;
  i18nCustomBaseTitle?: ProductsI18nCustomBaseTitle;
  productsFilters: FetchProductsFilters;
  disabled?: IsDisabled;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

function ProductsIndexPageCategoriesNav({
  filtersNavBasePath,
  customExitNavPath,
  i18nCustomBaseTitle,
  productsFilters,
  disabled,
  changeProductsFilters
}: ProductsIndexPageCategoriesNavProps) {
  const {
    productCategoriesWithParents,
    productCategoriesErrorMessage,
    productCategoriesFetched,
    productCategoriesIsPlaceholderData
  } = useThreeDStockProductsCategories({
    productsFilters
  });

  return (
    <div className="p-4">
      <AlertMessage message={productCategoriesErrorMessage} />
      <LoadingSkeleton
        loaded={!productCategoriesIsPlaceholderData && productCategoriesFetched}
      >
        <ProductsIndexPageCategoriesNavBody
          productCategories={productCategoriesWithParents}
          filtersNavBasePath={filtersNavBasePath}
          customExitNavPath={customExitNavPath}
          i18nCustomBaseTitle={i18nCustomBaseTitle}
          productsFilters={productsFilters}
          disabled={disabled}
          changeProductsFilters={changeProductsFilters}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default ProductsIndexPageCategoriesNav;
