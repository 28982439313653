import {
  FetchFinProductCategoriesFilters,
  FetchProductCategoriesCacheKey
} from '../../productsTypes';

import {
  FETCH_PRODUCT_STYLES_QUERY,
  FetchProductStylesQueryResponse
} from '../../queries/fetchProductStyles.query';

import { useFinPaginatedCategories } from '../../../categories/hooks/useFinPaginatedCategories';

import { FetchCategoriesScopes } from '../../../categories/categoriesTypes';

const defaultInitialFilters = {
  scope: { eq: FetchCategoriesScopes.FACILITY_STYLES }
};
const options = {
  staleTime: 1000 * 60 * 60
};

interface ProductStylesDefaultOptions {
  cacheKey: FetchProductCategoriesCacheKey;
  addInitialFilters?: FetchFinProductCategoriesFilters;
}

function useProductStyles({
  cacheKey,
  addInitialFilters
}: ProductStylesDefaultOptions) {
  const initialFilters = addInitialFilters
    ? { ...defaultInitialFilters, ...addInitialFilters }
    : defaultInitialFilters;

  const {
    categories,
    categoriesError,
    categoriesFetched,
    categoriesIsPlaceholderData
  } = useFinPaginatedCategories<FetchProductStylesQueryResponse>({
    cacheKey,
    query: FETCH_PRODUCT_STYLES_QUERY,
    initialFilters,
    options
  });

  return {
    productStyles: categories,
    productStylesErrorMessage: categoriesError,
    productStylesFetched: categoriesFetched,
    productStylesIsPlaceholderData: categoriesIsPlaceholderData
  };
}

export default useProductStyles;
