import {
  CreatedAt,
  CreatedAtFilter,
  CreateItemGqlQuery,
  CreateItemGqlStatus,
  DeleteItemGqlQuery,
  DeleteItemGqlStatus,
  FetchItemCacheKey,
  FetchItemGqlQuery,
  FetchItemsCacheKey,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  ID,
  ItemGqlError,
  UpdatedAt,
  DeletedAt,
  UpdateItemGqlQuery,
  UpdateItemGqlStatus,
  UUID,
  UUIDFilter,
  NanoIDFilter,
  DeviceNanoId,
  DeleteItemCacheKey,
  IdFilterType
} from '../../types';

import {
  ProductID,
  ProductUUID,
  ProductName,
  ProductBrandUUID,
  ProductBrandName,
  ProductImageUUID,
  ProductImageFile,
  ProductNda,
  ProductBlocked,
  ProductFavorite,
  ProductBrandLocalizedName,
  ProductNanoID
} from '../products/productsTypes';
import {
  UserFullName,
  UserImageFile,
  UserImageUUID,
  UserNanoID,
  UserClient,
  UserCurrentTeamNanoID
} from '../users/usersTypes';

export type SelectedProductID = ID;
export type SelectedProductIDs = SelectedProductID[];
export type SelectedProductUUID = UUID;
export type SelectedProductCreatedAt = CreatedAt;
export type SelectedProductUpdatedAt = UpdatedAt;
export type SelectedProductDeletedAt = DeletedAt;

export type SelectedProductDeviceNanoId = DeviceNanoId;

export type SelectedProductUserNanoId = UserNanoID;
export type SelectedProductUserFullName = UserFullName;
export type SelectedProductUserImageUUID = UserImageUUID;
export type SelectedProductUserImageFile = UserImageFile;
export type SelectedProductUserClient = UserClient;
export type SelectedProductUserCurrentTeamNanoID = UserCurrentTeamNanoID;

export type SelectedProductProductID = ProductID;
export type SelectedProductProductUUID = ProductUUID;
export type SelectedProductProductNanoID = ProductNanoID;
export type SelectedProductProductName = ProductName;
export type SelectedProductProductNda = ProductNda;
export type SelectedProductProductBlocked = ProductBlocked;
export type SelectedProductProductFavorite = ProductFavorite;

export type SelectedProductProductBrandUUID = ProductBrandUUID;
export type SelectedProductProductBrandName = ProductBrandName;
export type SelectedProductProductBrandLocalizedName =
  ProductBrandLocalizedName;
export type SelectedProductProductBrand = {
  localizedName: ProductBrandLocalizedName;
};

export type SelectedProductProductImageUUID = ProductImageUUID;
export type SelectedProductProductImageFile = ProductImageFile;

export interface FetchSelectedProductsFilters {
  id?: IdFilterType;
  taskUuid?: UUIDFilter;
  taskNanoId?: NanoIDFilter;
  projectUuid?: UUIDFilter;
  projectNanoId?: NanoIDFilter;
  createdAt?: CreatedAtFilter;
}

export enum FetchSelectedProductsSortableFields {
  ID = 'id',
  NAME = 'name',
  CREATED_AT = 'createdAt'
}

export enum FetchSelectedProductsSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export type FetchSelectedProductsGqlQuery = FetchItemsGqlQuery;
export type FetchSelectedProductGqlQuery = FetchItemGqlQuery;
export type CreateSelectedProductGqlQuery = CreateItemGqlQuery;
export type UpdateSelectedProductGqlQuery = UpdateItemGqlQuery;
export type DeleteSelectedProductGqlQuery = DeleteItemGqlQuery;
export type DownloadSelectedProductGqlQuery = UpdateItemGqlQuery;

export type CreateSelectedProductGqlStatus = CreateItemGqlStatus;
export type UpdateSelectedProductGqlStatus = UpdateItemGqlStatus;
export type DeleteSelectedProductGqlStatus = DeleteItemGqlStatus;
export type DownloadSelectedProductGqlStatus = UpdateItemGqlStatus;
export type SelectedProductGqlError = ItemGqlError;

export type FetchSelectedProductsCacheKey = FetchItemsCacheKey;
export type FetchSelectedProductCacheKey = FetchItemCacheKey;

export type FetchSelectedProductsFetched = FetchItemsFetched;
export type FetchSelectedProductsErrorMessage = FetchItemsErrorMessage;
export type FetchSelectedProductsIsPlaceholderData =
  FetchItemsIsPlaceholderData;
export type FetchSelectedProductsPage = FetchItemsPage;
export type FetchSelectedProductsLimit = FetchItemsLimit;
export type FetchSelectedProductsSort = FetchSelectedProductsSortTypes[];
export type FetchSelectedProductsTotalCount = FetchItemsTotalCount;
export type FetchSelectedProductsSortSelectedProducts = FetchItemsSortItems;
export type FetchSelectedProductsPaginateSelectedProducts =
  FetchItemsPaginateItems;
export type FetchSelectedProductsPrefetchSelectedProducts =
  FetchItemsPrefetchItems;
export type FetchSelectedProductsShowSelectedProduct = FetchItemsShowItem;
export type FetchSelectedProductsEditSelectedProduct = FetchItemsEditItem;

export type DeleteSelectedProductCacheKey = DeleteItemCacheKey;
export type DeleteSelectedProductCacheKeys = DeleteSelectedProductCacheKey[];
