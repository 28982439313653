import React, { useCallback } from 'react';

import { IsDisabled } from '../../../../../types';

import {
  FetchProductsFilters,
  ChangeProductsFiltersFunc
} from '../../../productsTypes';

import { Translate } from '../../../../../helpers/Translate';
import { Checkbox } from '../../../../../helpers/Checkbox';

import { formsFields, words } from '../../../../../locales/keys';

interface ProductsFavoriteCheckboxFilterProps {
  productsFilters: FetchProductsFilters;
  disabled?: IsDisabled;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

const name = 'favorite' as const;

function ProductsFavoriteCheckboxFilter({
  productsFilters,
  disabled,
  changeProductsFilters
}: ProductsFavoriteCheckboxFilterProps) {
  const checked = productsFilters?.[name];
  const handleClick = useCallback(() => {
    if (checked) {
      changeProductsFilters({}, [name]);
    } else {
      changeProductsFilters({ [name]: true });
    }
  }, [checked, changeProductsFilters]);

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={formsFields.favorites} />
        </h6>
      </div>
      <Checkbox
        checkboxWrapperClassName="flex items-center"
        i18nLabel={words.showFavoriteOnly}
        checked={checked}
        disabled={disabled}
        onChange={handleClick}
      />
    </div>
  );
}

export default ProductsFavoriteCheckboxFilter;
