import { FetchSelectedProductsSortTypes } from './selectedProductsTypes';

export const INITIAL_SELECTED_PRODUCTS_FILTERS = {};
export const INITIAL_SELECTED_PRODUCTS_PAGE = 1;
export const INITIAL_SELECTED_PRODUCTS_SORT = [
  FetchSelectedProductsSortTypes.CREATED_AT_DESC
];
export const INITIAL_SELECTED_PRODUCTS_LIMIT = 50;

export enum SelectedProductsPermissions {
  READ_SELECTED_PRODUCTS_DOWNLOAD_BUTTON = 'read_selected_products_download_button',
  READ_SELECTED_PRODUCT_FAVORITE_BUTTON = 'read_selected_product_favorite_button',
  CHANGE_SELECTED_PRODUCTS_CHECKED = 'change_selected_products_checked'
}
