import React, { useMemo } from 'react';

import { FilterBadgeProps } from '../../../../../helpers/filterBadges/types';
import { IdFilterFields } from '../../../../../types';

import { useProductSeasonal } from '../../../hooks/useProductSeasonal';

import { ItemsFilterBadge } from '../../../../../helpers/filterBadges/ItemsFilterBadge';

import { categoriesKeys } from '../../../../../locales/keys';

import { ProductCache } from '../../../ProductCache';

interface ProductsSeasonalFilterBadgeItem {
  id: string;
  label: string;
}

function ProductsSeasonalFilterBadge({
  filters,
  name,
  onRemoveFilter
}: FilterBadgeProps) {
  const { productSeasonal } = useProductSeasonal({
    cacheKey: ProductCache.seasonalFilterCacheKey()
  });

  const ProductsSeasonalFilterBadgeItems = useMemo<
    ProductsSeasonalFilterBadgeItem[]
  >(() => {
    return productSeasonal.map((category) => ({
      id: category.id as string,
      label: category.localizedName
    }));
  }, [productSeasonal]);

  return (
    <ItemsFilterBadge
      filters={filters}
      i18nLabel={categoriesKeys.seasonal}
      items={ProductsSeasonalFilterBadgeItems}
      name={name}
      onRemoveFilter={onRemoveFilter}
      filterField={IdFilterFields.IN}
    />
  );
}

export default ProductsSeasonalFilterBadge;
