import React from 'react';

import { IsDisabled } from '../../../../../../../types';

import {
  FetchProductsFilters,
  ProductsFiltersNavBasePath,
  ProductsCustomExitNavPath,
  ProductsI18nCustomBaseTitle,
  ChangeProductsFiltersFunc
} from '../../../../../productsTypes';

import useProductsIndexPageCategoriesNavBody from './useProductsIndexPageCategoriesNavBody';

import { ProductsIndexPageCategoriesNavCategory } from '../../ProductsIndexPageCategoriesNav.types';

import { ProductsIndexPageCategoriesNavHead } from '../ProductsIndexPageCategoriesNavHead';
import { ProductsIndexPageCategoriesNavList } from '../ProductsIndexPageCategoriesNavList';

import { getChangeProductsFiltersCategoriesData } from '../../../../../utils/getChangeProductsFiltersCategoriesData';

import { ProductPath } from '../../../../../ProductPath';

import { productsKeys } from '../../../../../../../locales/keys';

interface ProductsIndexPageCategoriesNavProps {
  productCategories: ProductsIndexPageCategoriesNavCategory[];
  filtersNavBasePath?: ProductsFiltersNavBasePath;
  customExitNavPath?: ProductsCustomExitNavPath;
  i18nCustomBaseTitle?: ProductsI18nCustomBaseTitle;
  productsFilters: FetchProductsFilters;
  disabled?: IsDisabled;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

function ProductsIndexPageCategoriesNavBody({
  productCategories,
  filtersNavBasePath,
  i18nCustomBaseTitle,
  customExitNavPath,
  productsFilters,
  disabled,
  changeProductsFilters
}: ProductsIndexPageCategoriesNavProps) {
  const {
    currentParentCategory,
    currentChildCategory,
    handleResetCategoriesFilters,
    handleParentCategoryChangeFilters
  } = useProductsIndexPageCategoriesNavBody({
    productCategories,
    productsFilters,
    changeProductsFilters
  });

  if (currentParentCategory && currentChildCategory) {
    return (
      <>
        <ProductsIndexPageCategoriesNavHead
          text={currentChildCategory.localizedName}
          backHref={
            filtersNavBasePath
              ? ProductPath.indexFiltersCustomBaseQuery(
                  filtersNavBasePath,
                  getChangeProductsFiltersCategoriesData({
                    productCategory: currentParentCategory,
                    categories: productCategories
                  }).changeFilters
                )
              : '#'
          }
          filtersNavBasePath={filtersNavBasePath}
          disabled={disabled}
          onChangeFiltersBack={handleParentCategoryChangeFilters}
        />
        <ProductsIndexPageCategoriesNavList
          productCategories={productCategories}
          parentCategoryId={currentChildCategory.id}
          filtersNavBasePath={filtersNavBasePath}
          disabled={disabled}
          changeProductsFilters={changeProductsFilters}
        />
      </>
    );
  }

  if (currentParentCategory) {
    const backHrefWithoutBasePath = !filtersNavBasePath && '#';
    const backHrefWithBasePath = currentParentCategory.parent?.id
      ? ProductPath.indexFiltersCustomBaseQuery(
          filtersNavBasePath,
          getChangeProductsFiltersCategoriesData({
            productCategory: currentParentCategory.parent,
            categories: productCategories
          }).changeFilters
        )
      : filtersNavBasePath;

    return (
      <>
        <ProductsIndexPageCategoriesNavHead
          text={currentParentCategory.localizedName}
          backHref={backHrefWithoutBasePath || backHrefWithBasePath}
          filtersNavBasePath={filtersNavBasePath}
          disabled={disabled}
          onChangeFiltersBack={handleResetCategoriesFilters}
        />
        <ProductsIndexPageCategoriesNavList
          productCategories={productCategories}
          parentCategoryId={currentParentCategory.id}
          filtersNavBasePath={filtersNavBasePath}
          disabled={disabled}
          changeProductsFilters={changeProductsFilters}
        />
      </>
    );
  }

  if (currentChildCategory) {
    return (
      <>
        <ProductsIndexPageCategoriesNavHead
          text={currentChildCategory.localizedName}
          backHref={filtersNavBasePath || '#'}
          filtersNavBasePath={filtersNavBasePath}
          disabled={disabled}
          onChangeFiltersBack={handleResetCategoriesFilters}
        />
        <ProductsIndexPageCategoriesNavList
          productCategories={productCategories}
          parentCategoryId={currentChildCategory.id}
          filtersNavBasePath={filtersNavBasePath}
          disabled={disabled}
          changeProductsFilters={changeProductsFilters}
        />
      </>
    );
  }

  return (
    <>
      <ProductsIndexPageCategoriesNavHead
        i18nText={i18nCustomBaseTitle || productsKeys.plural}
        filtersNavBasePath={filtersNavBasePath}
        backHref={customExitNavPath}
        disabled={disabled}
      />
      <ProductsIndexPageCategoriesNavList
        productCategories={productCategories}
        filtersNavBasePath={filtersNavBasePath}
        disabled={disabled}
        changeProductsFilters={changeProductsFilters}
      />
    </>
  );
}

export default ProductsIndexPageCategoriesNavBody;
