import React from 'react';
import cl from 'classnames';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { FetchProductsSetsCacheKeys } from '../../../../../../productsSets/productsSetsTypes';

import { FetchProductsCacheKeys } from '../../../../../../products/productsTypes';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import useSelectedProductsListItemLightboxFavoriteButton from './useSelectedProductsListItemLightboxFavoriteButton';

import { SelectedProductsListItemLightboxFavoriteButtonSelectedProduct } from './SelectedProductsListItemLightboxFavoriteButton.types';

import { words } from '../../../../../../../locales/keys';

interface SelectedProductsListItemLightboxFavoriteButtonProps {
  selectedProduct: SelectedProductsListItemLightboxFavoriteButtonSelectedProduct;
  productsCacheKeys?: FetchProductsCacheKeys;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
}

function SelectedProductsListItemLightboxFavoriteButton({
  selectedProduct,
  productsCacheKeys,
  productsSetCacheKeys
}: SelectedProductsListItemLightboxFavoriteButtonProps) {
  const { toggleProductFavoriteLoading, handleToggleFavorite } =
    useSelectedProductsListItemLightboxFavoriteButton({
      selectedProduct,
      productsCacheKeys,
      productsSetCacheKeys
    });

  return (
    <PureTooltipIconButtonHelper
      className={cl(
        'py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-white focus:ring-offset-0 hover:bg-white hover:bg-opacity-10 text-gray-300 hover:text-white',
        selectedProduct.product?.favorite ? 'text-pink-600' : null
      )}
      icon={
        selectedProduct.product?.favorite
          ? IconsEnum.HEART_SOLID
          : IconsEnum.HEART_OUTLINE
      }
      iconClassName={cl(
        'h-6 w-6',
        selectedProduct.product?.favorite ? 'p-0.5' : null
      )}
      tooltipSingleton
      tooltipI18nText={
        selectedProduct.product?.favorite
          ? words.removeFromFavorites
          : words.addToFavorites
      }
      disabled={toggleProductFavoriteLoading}
      onClick={handleToggleFavorite}
    />
  );
}

export default SelectedProductsListItemLightboxFavoriteButton;
