import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

import { IsDisabled } from '../../../../../types';

import {
  FetchProductsFilters,
  ProductClientID,
  ChangeProductsFiltersFunc
} from '../../../productsTypes';

import { useProductColors } from '../../../hooks/useProductColors';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { ColorsSelectFilter } from '../../../../../helpers/filters/ColorsSelectFilter';
import { Translate } from '../../../../../helpers/Translate';

import { getProductClientIdsCacheKeyPart } from '../../../utils/getProductClientIdsCacheKeyPart';

import { categoriesKeys } from '../../../../../locales/keys';

import { ProductsColorsFilterDataItem } from './ProductsColorsFilter.types';

import { ProductCache } from '../../../ProductCache';

interface ProductsColorsFilterProps {
  clientIds?: ProductClientID[];
  productsFilters: FetchProductsFilters;
  disabled?: IsDisabled;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

const name = 'colorIds' as const;

function ProductsColorsFilter({
  clientIds,
  productsFilters,
  disabled,
  changeProductsFilters
}: ProductsColorsFilterProps) {
  const cacheKey = isEmpty(clientIds)
    ? ProductCache.colorsFilterCacheKey()
    : ProductCache.colorsLibraryFilterCacheKey(
        getProductClientIdsCacheKeyPart({ clientIds })
      );
  const addInitialFilters = isEmpty(clientIds)
    ? undefined
    : { productClientIds: clientIds };

  const {
    productColors,
    productColorsErrorMessage,
    productColorsFetched,
    productColorsIsPlaceholderData
  } = useProductColors({
    cacheKey,
    addInitialFilters
  });

  const productColorsItems = useMemo<ProductsColorsFilterDataItem[]>(() => {
    return productColors.map((category) => ({
      value: category.id as string,
      label: category.localizedName,
      color: category.nameEn
    }));
  }, [productColors]);

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={categoriesKeys.colors.singular} />
        </h6>
      </div>
      <AlertMessage message={productColorsErrorMessage} />
      <LoadingSkeleton
        loaded={productColorsIsPlaceholderData || productColorsFetched}
      >
        <ColorsSelectFilter
          name={name}
          items={productColorsItems}
          value={productsFilters?.[name] as string[]}
          disabled={disabled}
          onChange={changeProductsFilters}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default ProductsColorsFilter;
