import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import {
  DownloadSelectedProductGqlQuery,
  DownloadSelectedProductGqlStatus,
  SelectedProductDeviceNanoId,
  SelectedProductGqlError,
  SelectedProductUUID
} from '../../selectedProductsTypes';

interface DownloadSelectedProductOptions {
  query: DownloadSelectedProductGqlQuery;
  cacheKeys?: string[];
}

export interface DownloadSelectedProductResponse<
  DownloadSelectedProductRecordType
> {
  downloadSelectedProduct: {
    status: DownloadSelectedProductGqlStatus;
    recordUuid: SelectedProductUUID;
    record: DownloadSelectedProductRecordType;
    errors: DownloadSelectedProductError;
  };
}

export interface DownloadSelectedProductInput {
  uuid: SelectedProductUUID;
  deviceNanoId: SelectedProductDeviceNanoId;
}

export interface DownloadSelectedProductError {
  fullMessages: SelectedProductGqlError;
}

const action = 'downloadSelectedProduct';

function useDownloadSelectedProduct<DownloadSelectedProductRecordType>({
  query,
  cacheKeys
}: DownloadSelectedProductOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    DownloadSelectedProductInput,
    DownloadSelectedProductResponse<DownloadSelectedProductRecordType>,
    DownloadSelectedProductError,
    DownloadSelectedProductRecordType
  >({ action, query, cacheKeys });

  return {
    downloadSelectedProductData: updateQueryData,
    downloadSelectedProductError: updateQueryError,
    downloadSelectedProductLoading: updateQueryLoading,
    downloadSelectedProductErrorMessage: updateQueryErrorMessage,
    downloadSelectedProduct: updateQuery,
    downloadSelectedProductReset: updateQueryReset
  };
}

export default useDownloadSelectedProduct;
