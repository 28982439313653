import {
  FetchFinProductCategoriesFilters,
  FetchProductCategoriesCacheKey
} from '../../productsTypes';

import {
  FETCH_PRODUCT_TYPES_QUERY,
  FetchProductTypesQueryResponse
} from '../../queries/fetchProductTypes.query';

import { useFinPaginatedCategories } from '../../../categories/hooks/useFinPaginatedCategories';

import { FetchCategoriesScopes } from '../../../categories/categoriesTypes';

const defaultInitialFilters = {
  scope: { eq: FetchCategoriesScopes.PRODUCTS_TYPE }
};
const options = {
  staleTime: 1000 * 60 * 60
};

interface ProductTypesDefaultOptions {
  cacheKey: FetchProductCategoriesCacheKey;
  addInitialFilters?: FetchFinProductCategoriesFilters;
}

function useProductTypes({
  cacheKey,
  addInitialFilters
}: ProductTypesDefaultOptions) {
  const initialFilters = addInitialFilters
    ? { ...defaultInitialFilters, ...addInitialFilters }
    : defaultInitialFilters;

  const {
    categories,
    categoriesError,
    categoriesFetched,
    categoriesIsPlaceholderData
  } = useFinPaginatedCategories<FetchProductTypesQueryResponse>({
    cacheKey,
    query: FETCH_PRODUCT_TYPES_QUERY,
    initialFilters,
    options
  });

  return {
    productTypes: categories,
    productTypesErrorMessage: categoriesError,
    productTypesFetched: categoriesFetched,
    productTypesIsPlaceholderData: categoriesIsPlaceholderData
  };
}

export default useProductTypes;
