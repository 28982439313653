import { gql } from 'graphql-request';

import {
  CategoryID,
  CategoryUUID,
  CategoryName
} from '../../categories/categoriesTypes';

export interface FetchProductBrandsSelectedQueryResponse {
  uuid: CategoryUUID;
  id: CategoryID;
  name: CategoryName;
}

export const FETCH_PRODUCT_BRANDS_SELECTED_QUERY = gql`
  query ProductBrandsSelectedCategories(
    $filters: CategoriesFilters
    $sort: [CategoriesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    categories(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        id
        name
        uuid
      }
    }
  }
`;
