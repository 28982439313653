import React, { useMemo, useState } from 'react';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import size from 'lodash/size';
import take from 'lodash/take';

import { ID, IsDisabled } from '../../../../../../../types';

import {
  ProductsFiltersNavBasePath,
  ChangeProductsFiltersFunc
} from '../../../../../productsTypes';

import { ProductsIndexPageCategoriesNavCategory } from '../../ProductsIndexPageCategoriesNav.types';

import { ProductsIndexPageCategoriesNavItem } from '../ProductsIndexPageCategoriesNavItem';
import { ProductsIndexPageCategoriesNavAmountButton } from '../ProductsIndexPageCategoriesNavAmountButton';

interface ProductsIndexPageCategoriesNavListProps {
  productCategories: ProductsIndexPageCategoriesNavCategory[];
  parentCategoryId?: ID;
  filtersNavBasePath?: ProductsFiltersNavBasePath;
  disabled?: IsDisabled;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

const defaultListCategoriesSize = 8;

function ProductsIndexPageCategoriesNavList({
  productCategories,
  parentCategoryId,
  filtersNavBasePath,
  disabled,
  changeProductsFilters
}: ProductsIndexPageCategoriesNavListProps) {
  const listCategories = useMemo<ProductsIndexPageCategoriesNavCategory[]>(
    () =>
      sortBy(
        filter(productCategories, (category) =>
          parentCategoryId
            ? category.parent?.id === parentCategoryId
            : !category.parent
        ),
        'localizedName'
      ),
    [productCategories, parentCategoryId]
  );

  const [showAll, setShowAll] = useState(false);

  if (size(listCategories) <= defaultListCategoriesSize) {
    return (
      <>
        {listCategories.map((category) => (
          <ProductsIndexPageCategoriesNavItem
            key={category.id}
            category={category}
            categories={productCategories}
            filtersNavBasePath={filtersNavBasePath}
            disabled={disabled}
            changeProductsFilters={changeProductsFilters}
          />
        ))}
      </>
    );
  }

  const showedCategories = showAll
    ? listCategories
    : take(listCategories, defaultListCategoriesSize);

  return (
    <>
      {showedCategories.map((category) => (
        <ProductsIndexPageCategoriesNavItem
          key={category.id}
          category={category}
          categories={productCategories}
          filtersNavBasePath={filtersNavBasePath}
          disabled={disabled}
          changeProductsFilters={changeProductsFilters}
        />
      ))}
      <ProductsIndexPageCategoriesNavAmountButton
        showAll={showAll}
        disabled={disabled}
        setShowAll={setShowAll}
      />
    </>
  );
}

export default ProductsIndexPageCategoriesNavList;
