import React, { useCallback } from 'react';
import cl from 'classnames';

import { IsDisabled } from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import {
  ProductsFiltersNavBasePath,
  ChangeProductsFiltersFunc
} from '../../../../../productsTypes';
import { ProductsIndexPageCategoriesNavCategory } from '../../ProductsIndexPageCategoriesNav.types';

import { NextLinkHelper } from '../../../../../../../helpers/links/NextLinkHelper';
import { Icon } from '../../../../../../../helpers/Icon';
import { getChangeProductsFiltersCategoriesData } from '../../../../../utils/getChangeProductsFiltersCategoriesData';

import { ProductPath } from '../../../../../ProductPath';

interface ProductsIndexPageCategoriesNavItemProps {
  categories: ProductsIndexPageCategoriesNavCategory[];
  category: ProductsIndexPageCategoriesNavCategory;
  changeProductsFilters: ChangeProductsFiltersFunc;
  disabled?: IsDisabled;
  filtersNavBasePath?: ProductsFiltersNavBasePath;
  withChevron?: boolean;
}

function ProductsIndexPageCategoriesNavItem({
  categories,
  category,
  changeProductsFilters,
  disabled,
  filtersNavBasePath,
  withChevron
}: ProductsIndexPageCategoriesNavItemProps) {
  const handleLinkClick = useCallback<
    (e: React.MouseEvent<HTMLAnchorElement>) => void
  >(
    (e) => {
      if (!filtersNavBasePath) {
        e.preventDefault();
        const { changeFilters, resetFilters } =
          getChangeProductsFiltersCategoriesData({
            productCategory: category,
            categories
          });

        changeProductsFilters(changeFilters, resetFilters);
      }
    },
    [filtersNavBasePath, category, categories, changeProductsFilters]
  );

  return (
    <div className="-mx-4">
      <div className="flex items-center group relative px-4 py-2 transition-colors ease-in-out duration-150 focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100 bg-gray-500 bg-opacity-0 hover:bg-opacity-20">
        <NextLinkHelper
          className={cl(
            'focus:outline-none flex-1 px-2 text-sm',
            disabled ? 'pointer-events-none' : null
          )}
          href={
            filtersNavBasePath
              ? ProductPath.indexFiltersCustomBaseQuery(
                  filtersNavBasePath,
                  getChangeProductsFiltersCategoriesData({
                    productCategory: category,
                    categories
                  }).changeFilters
                )
              : '#'
          }
          onClick={handleLinkClick}
        >
          {category.localizedName}
          <span className="absolute inset-0" aria-hidden="true" />
        </NextLinkHelper>

        {withChevron && (
          <Icon
            icon={IconsEnum.CHEVRON_RIGHT}
            className="h-5 w-5 flex-shrink-0"
          />
        )}
      </div>
    </div>
  );
}

export default ProductsIndexPageCategoriesNavItem;
