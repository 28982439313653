import React, { memo } from 'react';

import { SelectedLibraryItemsFloatingCounter } from '../../../common/components/SelectedLibraryItemsFloatingCounter';

import {
  UpdateProductsSetCacheAction,
  FetchProductsSetsCacheKey
} from '../../../productsSets/productsSetsTypes';

import {
  OnSelectedProductsSidebarOpenAction,
  OnSelectedProductsSidebarCloseAction
} from '../../../products/productsTypes';

import useSelectedProductsFloatingCounter from './useSelectedProductsFloatingCounter';

import {
  SelectedProductsFloatingCounterProductsSet,
  SelectedProductsFloatingCounterOnProductsSelect
} from './SelectedProductsFloatingCounter.types';

interface SelectedProductsFloatingCounterProps {
  productsSet: SelectedProductsFloatingCounterProductsSet;
  productsSetCacheKey?: FetchProductsSetsCacheKey;
  isOpen: boolean;
  onOpen: OnSelectedProductsSidebarOpenAction;
  onClose: OnSelectedProductsSidebarCloseAction;
  updateProductsSetCache: UpdateProductsSetCacheAction<SelectedProductsFloatingCounterProductsSet>;
  onProductsSelect?: SelectedProductsFloatingCounterOnProductsSelect;
}

function SelectedProductsFloatingCounter({
  productsSet,
  productsSetCacheKey,
  isOpen,
  onOpen,
  onClose,
  updateProductsSetCache,
  onProductsSelect
}: SelectedProductsFloatingCounterProps) {
  const {
    selectedProductsCount,
    emptyProductsSetLoading,
    handleEmptyProductsSet
  } = useSelectedProductsFloatingCounter({
    productsSet,
    productsSetCacheKey,
    onClose,
    updateProductsSetCache,
    onProductsSelect
  });

  return (
    <SelectedLibraryItemsFloatingCounter
      itemsCount={selectedProductsCount}
      clearLoading={emptyProductsSetLoading}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      onClear={handleEmptyProductsSet}
    />
  );
}

export default memo<SelectedProductsFloatingCounterProps>(
  SelectedProductsFloatingCounter
);
