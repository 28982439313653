import { useCallback } from 'react';
import size from 'lodash/size';
import compact from 'lodash/compact';

import {
  UpdateProductsSetCacheAction,
  FetchProductsSetsCacheKeys
} from '../../../../../../productsSets/productsSetsTypes';

import { OnSelectedProductsSidebarCloseAction } from '../../../../../../products/productsTypes';

import {
  TOGGLE_PRODUCT_IN_PRODUCTS_SET_QUERY,
  ToggleProductInProductsSetQueryResponse
} from '../../../../../../productsSets/queries/toggleProductInProductsSet.query';

import { useToggleProductInProductsSet } from '../../../../../../productsSets/hooks/useToggleProductInProductsSet';

import { useToastNotification } from '../../../../../../../common/hooks/useToastNotification';

import { parseRequestError } from '../../../../../../../utils/parseRequestError';

import { ProductsSetCache } from '../../../../../../productsSets/ProductsSetCache';

import {
  SelectedProductsListItemDeleteButtonSelectedProduct,
  SelectedProductsListItemDeleteButtonProductsSet
} from './SelectedProductsListItemDeleteButton.types';

interface UseSelectedProductsListItemDeleteButtonOptions {
  selectedProduct: SelectedProductsListItemDeleteButtonSelectedProduct;
  productsSet: SelectedProductsListItemDeleteButtonProductsSet;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
  onSelectedProductsSidebarClose: OnSelectedProductsSidebarCloseAction;
  updateProductsSetCache: UpdateProductsSetCacheAction<SelectedProductsListItemDeleteButtonProductsSet>;
}

function useSelectedProductsListItemDeleteButton({
  selectedProduct,
  productsSet,
  productsSetCacheKeys,
  onSelectedProductsSidebarClose,
  updateProductsSetCache
}: UseSelectedProductsListItemDeleteButtonOptions) {
  const { toggleProductInProductsSet } =
    useToggleProductInProductsSet<ToggleProductInProductsSetQueryResponse>({
      query: TOGGLE_PRODUCT_IN_PRODUCTS_SET_QUERY,
      cacheKeys: compact([
        ProductsSetCache.showCacheKey(),
        ...(productsSetCacheKeys || [])
      ]),
      product: selectedProduct.product,
      updateProductsSetCache
    });

  const { showToastNotification } = useToastNotification({
    appearance: 'error'
  });

  const selectedProductsAmount = size(productsSet?.selectedProducts);

  const handleAfterToggle = useCallback(() => {
    if (selectedProductsAmount === 1) {
      onSelectedProductsSidebarClose();
    }
  }, [selectedProductsAmount, onSelectedProductsSidebarClose]);

  const productsSetUuid = productsSet?.uuid;
  const productId = selectedProduct?.product?.id;

  const handleToggleSelected = useCallback(() => {
    if (!productsSetUuid || !productId) {
      return;
    }

    toggleProductInProductsSet({
      uuid: productsSetUuid,
      productId
    }).catch((error) => {
      showToastNotification(parseRequestError(error));
    });

    handleAfterToggle();
  }, [
    productsSetUuid,
    productId,
    toggleProductInProductsSet,
    showToastNotification,
    handleAfterToggle
  ]);

  return {
    handleToggleSelected
  };
}

export default useSelectedProductsListItemDeleteButton;
