import React from 'react';
import {
  FetchProductsCacheKey,
  FetchProductsSort
} from '../../../productsTypes';
import { IsDisabled, FetchItemsSort } from '../../../../../types';

import { useProductsSortDropdown } from '../../../hooks/useProductsSortDropdown';

import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';
import { DropdownHelper } from '../../../../../helpers/dropdowns/DropdownHelper';

import { words } from '../../../../../locales/keys';
import { productsSortI18nTextTypes } from '../../../productsConstants';

interface ProductsFiltersDropdownButtonProps {
  currentSort: FetchItemsSort;
  disabled?: IsDisabled;
  productsBaseCacheKey: FetchProductsCacheKey;
  sortProducts: (nextSort: FetchProductsSort) => void;
}

function ProductsSortDropdownButton({
  currentSort,
  disabled,
  productsBaseCacheKey,
  sortProducts
}: ProductsFiltersDropdownButtonProps) {
  const { handleSortByMostLiked, handleSortByMostUsed, handleSortByNewest } =
    useProductsSortDropdown({
      productsBaseCacheKey,
      sortProducts
    });

  return (
    <DropdownHelper
      buttonClassName="py-1.5 pl-3 pr-3 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
      i18nText={productsSortI18nTextTypes[currentSort?.toString()]}
      disabled={disabled}
    >
      <PureButtonHelper
        className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
        i18nText={words.mostLiked}
        onClick={handleSortByMostLiked}
      />
      <PureButtonHelper
        className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
        i18nText={words.mostUsed}
        onClick={handleSortByMostUsed}
      />
      <PureButtonHelper
        className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
        i18nText={words.newest}
        onClick={handleSortByNewest}
      />
    </DropdownHelper>
  );
}

export default ProductsSortDropdownButton;
