import { useCallback } from 'react';

import {
  FetchProductsCacheKey,
  FetchProductsSort,
  FetchProductsSortTypes
} from '../../productsTypes';

import { useProductsFiltersCache } from '../useProductsFiltersCache';

interface ProductsSortDropdownOptions {
  productsBaseCacheKey: FetchProductsCacheKey;
  sortProducts: (nextSort: FetchProductsSort) => void;
}

function useProductsSortDropdown({
  productsBaseCacheKey,
  sortProducts
}: ProductsSortDropdownOptions) {
  const { setCachedProductsSort } = useProductsFiltersCache({
    cacheKey: productsBaseCacheKey
  });

  const handleSortByMostLiked = useCallback<() => void>(() => {
    setCachedProductsSort([
      FetchProductsSortTypes.USERS_CHOSEN_PRODUCTS_COUNT_DESC
    ]);
    sortProducts([FetchProductsSortTypes.USERS_CHOSEN_PRODUCTS_COUNT_DESC]);
  }, [sortProducts, setCachedProductsSort]);

  const handleSortByMostUsed = useCallback<() => void>(() => {
    setCachedProductsSort([
      FetchProductsSortTypes.SELECTED_PRODUCTS_COUNT_DESC
    ]);
    sortProducts([FetchProductsSortTypes.SELECTED_PRODUCTS_COUNT_DESC]);
  }, [sortProducts, setCachedProductsSort]);

  const handleSortByNewest = useCallback<() => void>(() => {
    setCachedProductsSort([FetchProductsSortTypes.CREATED_AT_DESC]);
    sortProducts([FetchProductsSortTypes.CREATED_AT_DESC]);
  }, [sortProducts, setCachedProductsSort]);

  return {
    handleSortByMostLiked,
    handleSortByMostUsed,
    handleSortByNewest
  };
}

export default useProductsSortDropdown;
