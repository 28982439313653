import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import { IsDisabled } from '../../../../../types';

import {
  FetchProductsFilters,
  ChangeProductsFiltersFunc
} from '../../../productsTypes';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { words } from '../../../../../locales/keys';

interface ProductsFavoriteButtonFilterProps {
  productsFilters: FetchProductsFilters;
  disabled?: IsDisabled;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

const name = 'favorite' as const;

function ProductsFavoriteButtonFilter({
  productsFilters,
  disabled,
  changeProductsFilters
}: ProductsFavoriteButtonFilterProps) {
  const checked = productsFilters?.[name];
  const handleClick = useCallback(() => {
    if (checked) {
      changeProductsFilters({}, [name]);
    } else {
      changeProductsFilters({ [name]: true });
    }
  }, [checked, changeProductsFilters]);

  return (
    <PureTooltipIconButtonHelper
      className="py-1.5 pl-1.5 pr-1.5 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
      icon={checked ? IconsEnum.HEART_SOLID : IconsEnum.HEART_OUTLINE}
      iconClassName="h-5 w-5"
      tooltipI18nText={words.filterFavorite}
      tooltipPlacement={TooltipPlacement.TOP}
      disabled={disabled}
      onClick={handleClick}
    />
  );
}

export default ProductsFavoriteButtonFilter;
