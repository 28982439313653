import { gql } from 'graphql-request';

import {
  ProductsSetSelectedProductsUUID,
  ProductsSetUUID
} from '../productsSetsTypes';

export interface EmptyProductsSetInput {
  clientMutationId?: string;
  uuid: ProductsSetUUID;
}

export interface EmptyProductsSetError {
  fullMessages: string[] | null;
}

export interface EmptyProductsSetResponse {
  emptyProductsSet: {
    status: string;
    errors: EmptyProductsSetError;
  };
}

export interface EmptyProductsSetQueryResponse {
  uuid: ProductsSetUUID;
  selectedProducts: {
    uuid: ProductsSetSelectedProductsUUID;
  }[];
}

export const action = 'emptyProductsSet';

export const EMPTY_PRODUCTS_SET_QUERY = gql`
  mutation EmptyProductsSet($uuid: ID!) {
    emptyProductsSet(input: { uuid: $uuid }) {
      status
      recordUuid
      record {
        uuid
        selectedProducts {
          uuid
        }
      }
      errors {
        fullMessages
      }
    }
  }
`;
