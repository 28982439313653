import React, { useMemo } from 'react';

import { FilterBadgeProps } from '../../../../../helpers/filterBadges/types';
import { IdFilterFields } from '../../../../../types';

import { useProductStyles } from '../../../hooks/useProductStyles';

import { ItemsFilterBadge } from '../../../../../helpers/filterBadges/ItemsFilterBadge';

import { categoriesKeys } from '../../../../../locales/keys';

import { ProductCache } from '../../../ProductCache';

interface ProductsStylesFilterBadgeItem {
  id: string;
  label: string;
}

function ProductsStylesFilterBadge({
  filters,
  name,
  onRemoveFilter
}: FilterBadgeProps) {
  const { productStyles } = useProductStyles({
    cacheKey: ProductCache.stylesFilterCacheKey()
  });

  const ProductsStylesFilterBadgeItems = useMemo<
    ProductsStylesFilterBadgeItem[]
  >(() => {
    return productStyles.map((category) => ({
      id: category.id as string,
      label: category.localizedName
    }));
  }, [productStyles]);

  return (
    <ItemsFilterBadge
      filters={filters}
      i18nLabel={categoriesKeys.styles.singular}
      items={ProductsStylesFilterBadgeItems}
      name={name}
      onRemoveFilter={onRemoveFilter}
      filterField={IdFilterFields.IN}
    />
  );
}

export default ProductsStylesFilterBadge;
