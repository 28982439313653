import { useCallback } from 'react';
import compact from 'lodash/compact';

import { FetchProductsCacheKeys } from '../../../../../../products/productsTypes';
import { FetchProductsSetsCacheKeys } from '../../../../../../productsSets/productsSetsTypes';

import {
  TOGGLE_PRODUCT_FAVORITE_QUERY,
  ToggleProductFavoriteQueryResponse
} from '../../../../../../products/queries/toggleProductFavorite.query';

import { useToggleProductFavorite } from '../../../../../../products/hooks/useToggleProductFavorite';

import { useShowToastOnErrorChange } from '../../../../../../../common/hooks/useShowToastOnErrorChange';

import { ProductCache } from '../../../../../../products/ProductCache';
import { ProductsSetCache } from '../../../../../../productsSets/ProductsSetCache';

import { SelectedProductsListItemLightboxFavoriteButtonSelectedProduct } from './SelectedProductsListItemLightboxFavoriteButton.types';

interface SelectedProductsListItemLightboxFavoriteButtonOptions {
  selectedProduct: SelectedProductsListItemLightboxFavoriteButtonSelectedProduct;
  productsCacheKeys?: FetchProductsCacheKeys;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
}

function useSelectedProductsListItemLightboxFavoriteButton({
  selectedProduct,
  productsCacheKeys,
  productsSetCacheKeys
}: SelectedProductsListItemLightboxFavoriteButtonOptions) {
  const {
    toggleProductFavorite,
    toggleProductFavoriteLoading,
    toggleProductFavoriteErrorMessage
  } = useToggleProductFavorite<ToggleProductFavoriteQueryResponse>({
    query: TOGGLE_PRODUCT_FAVORITE_QUERY,
    cacheKeys: compact([
      ProductCache.indexCacheKey(),
      ProductsSetCache.showCacheKey(),
      ...(productsCacheKeys || []),
      ...(productsSetCacheKeys || [])
    ])
  });

  const handleToggleFavorite = useCallback<() => void>(
    () => toggleProductFavorite({ uuid: selectedProduct?.product?.uuid }),
    [selectedProduct, toggleProductFavorite]
  );

  useShowToastOnErrorChange({ error: toggleProductFavoriteErrorMessage });

  return {
    toggleProductFavoriteLoading,
    handleToggleFavorite
  };
}

export default useSelectedProductsListItemLightboxFavoriteButton;
