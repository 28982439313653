import { gql } from 'graphql-request';

import { SelectedProductUUID } from '../selectedProductsTypes';

export interface DownloadSelectedProductQueryResponse {
  uuid: SelectedProductUUID;
}

export const DOWNLOAD_SELECTED_PRODUCT_QUERY = gql`
  mutation DownloadSelectedProduct($uuid: ID!, $deviceNanoId: String!) {
    downloadSelectedProduct(
      input: { uuid: $uuid, deviceNanoId: $deviceNanoId }
    ) {
      recordUuid
      status
      record {
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
