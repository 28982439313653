import React, { useEffect, useMemo } from 'react';
import isEqual from 'lodash/isEqual';

import { IdFilterFields } from '../../../../../types';
import { FilterBadgeProps } from '../../../../../helpers/filterBadges/types';
import { FetchCategoriesScopes } from '../../../../categories/categoriesTypes';

import {
  FETCH_PRODUCT_BRANDS_SELECTED_QUERY,
  FetchProductBrandsSelectedQueryResponse
} from '../../../queries/fetchProductBrandsSelected.query';

import { useFinPaginatedCategories } from '../../../../categories/hooks/useFinPaginatedCategories';

import { ItemsFilterBadge } from '../../../../../helpers/filterBadges/ItemsFilterBadge';

import { categoriesKeys } from '../../../../../locales/keys';

import { ProductCache } from '../../../ProductCache';

interface ProductsBrandsFilterBadgeItem {
  id: string;
  label: string;
}

const staleTime = 1000 * 60 * 60;

function ProductsBrandsFilterBadge({
  filters,
  name,
  onRemoveFilter
}: FilterBadgeProps) {
  const filterValue = useMemo(() => filters?.[name]?.in || [], [filters, name]);

  const {
    categories: productBrandsSelected,
    categoriesFilters: productsBrandsSelectedFilters,
    changeCategoriesFilters: changeProductBrandsSelectedFilters
  } = useFinPaginatedCategories<FetchProductBrandsSelectedQueryResponse>({
    cacheKey: ProductCache.brandsSelectedFilterCacheKey(),
    query: FETCH_PRODUCT_BRANDS_SELECTED_QUERY,
    initialFilters: {
      scope: { eq: FetchCategoriesScopes.FACILITY_MANUFACTURERS },
      id: { in: filterValue }
    },
    options: {
      staleTime,
      keepPreviousData: true
    }
  });

  useEffect(() => {
    if (!isEqual(filterValue, productsBrandsSelectedFilters.id?.in)) {
      changeProductBrandsSelectedFilters({
        id: { in: filterValue }
      });
    }
  }, [
    changeProductBrandsSelectedFilters,
    filterValue,
    productsBrandsSelectedFilters.id?.in
  ]);

  const productBrandsSelectedData = useMemo<ProductsBrandsFilterBadgeItem[]>(
    () =>
      productBrandsSelected.map((category) => ({
        id: category.id as string,
        label: category.name
      })),
    [productBrandsSelected]
  );

  return (
    <ItemsFilterBadge
      filters={filters}
      i18nLabel={categoriesKeys.brands.plural}
      items={productBrandsSelectedData}
      name={name}
      onRemoveFilter={onRemoveFilter}
      filterField={IdFilterFields.IN}
    />
  );
}

export default ProductsBrandsFilterBadge;
