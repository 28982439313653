import {
  FetchFinProductCategoriesFilters,
  FetchProductCategoriesCacheKey
} from '../../productsTypes';

import {
  FETCH_PRODUCT_COLORS_QUERY,
  FetchProductColorsQueryResponse
} from '../../queries/fetchProductColors.query';

import { useFinPaginatedCategories } from '../../../categories/hooks/useFinPaginatedCategories';

import { FetchCategoriesScopes } from '../../../categories/categoriesTypes';

const defaultInitialFilters = {
  scope: { eq: FetchCategoriesScopes.FACILITY_COLORS }
};
const options = {
  staleTime: 1000 * 60 * 60
};

interface ProductColorsDefaultOptions {
  cacheKey: FetchProductCategoriesCacheKey;
  addInitialFilters?: FetchFinProductCategoriesFilters;
}

function useProductColors({
  cacheKey,
  addInitialFilters
}: ProductColorsDefaultOptions) {
  const initialFilters = addInitialFilters
    ? { ...defaultInitialFilters, ...addInitialFilters }
    : defaultInitialFilters;

  const {
    categories,
    categoriesError,
    categoriesFetched,
    categoriesIsPlaceholderData
  } = useFinPaginatedCategories<FetchProductColorsQueryResponse>({
    cacheKey,
    query: FETCH_PRODUCT_COLORS_QUERY,
    initialFilters,
    options
  });

  return {
    productColors: categories,
    productColorsErrorMessage: categoriesError,
    productColorsFetched: categoriesFetched,
    productColorsIsPlaceholderData: categoriesIsPlaceholderData
  };
}

export default useProductColors;
