import React, { memo, Fragment, useMemo, useCallback } from 'react';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import size from 'lodash/size';

import {
  IsFetched,
  ErrorMessage,
  IsPlaceholderData
} from '../../../../../types';
import {
  Checkable,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../../../../common/hooks/useTableCheckable';

import { TogglePreventModalCloseAction } from '../../../../../helpers/modals/modalsTypes';

import {
  OnSelectedProductsSidebarCloseAction,
  FetchProductsCacheKeys
} from '../../../../products/productsTypes';
import {
  SelectedProductsListProductsSet,
  SelectedProductsListProductsSetSelectedProducts,
  SelectedProductsListProductsSetSelectedProduct,
  SelectedProductsListUpdateProductsSetCache
} from './SelectedProductsList.types';

import { FetchProductsSetsCacheKeys } from '../../../../productsSets/productsSetsTypes';

import { SelectedProductsListItem } from '../SelectedProductsListItem';

import { SelectedProductsListItemLightboxBottomButtons } from './components/SelectedProductsListItemLightboxBottomButtons';
import { SelectedProductsListItemLightboxTitle } from './components/SelectedProductsListItemLightboxTitle';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { Loading } from '../../../../../helpers/Loading';
import { NoResults } from '../../../../../helpers/NoResults';
import { Checkbox } from '../../../../../helpers/Checkbox';

import {
  LightboxWrapper,
  useLightboxWrapper,
  LightboxRenderCustomButtons,
  LightboxRenderImageTitle
} from '../../../../../helpers/LightboxWrapper';

import { Files } from '../../../../../utils/Files';

import { productsKeys } from '../../../../../locales/keys';

interface SelectedProductsListProps {
  productsSet: SelectedProductsListProductsSet;
  productsSetFetched: IsFetched;
  productsSetError: ErrorMessage;
  productsSetIsPlaceholderData: IsPlaceholderData;
  productsCacheKeys?: FetchProductsCacheKeys;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
  onSelectedProductsSidebarClose: OnSelectedProductsSidebarCloseAction;
  updateProductsSetCache: SelectedProductsListUpdateProductsSetCache;
  togglePreventModalClose?: TogglePreventModalCloseAction;
  checkable?: Checkable;
  checkedAll?: CheckedAll;
  checkedHash?: CheckedHashItem;
  onCheckAll?: OnCheckAll;
  onSetCheckedIds?: OnSetCheckedIds;
}

function SelectedProductsList({
  productsSet,
  productsSetFetched,
  productsSetError,
  productsSetIsPlaceholderData,
  productsCacheKeys,
  productsSetCacheKeys,
  onSelectedProductsSidebarClose,
  updateProductsSetCache,
  togglePreventModalClose,
  checkable = false,
  checkedAll,
  checkedHash,
  onCheckAll,
  onSetCheckedIds
}: SelectedProductsListProps) {
  const selectedProducts =
    useMemo<SelectedProductsListProductsSetSelectedProducts>(
      () => productsSet?.selectedProducts || [],
      [productsSet]
    );

  const selectedProductsTotalCount = size(selectedProducts);

  const selectedProductsInLightbox =
    useMemo<SelectedProductsListProductsSetSelectedProducts>(() => {
      return filter<SelectedProductsListProductsSetSelectedProduct>(
        selectedProducts,
        (selectedProduct) =>
          selectedProduct.product?.image?.file &&
          Files.isImage(selectedProduct.product.image.file)
      );
    }, [selectedProducts]);

  const lightboxItems = useMemo(() => {
    const productImages = selectedProductsInLightbox.map((selectedProduct) =>
      selectedProduct.product?.image?.file &&
      Files.isImage(selectedProduct.product.image.file)
        ? selectedProduct.product.image
        : null
    );

    return compact(productImages);
  }, [selectedProductsInLightbox]);

  const {
    handleLightboxClose,
    handleLightboxNext,
    handleLightboxOpen,
    handleLightboxOpenOnSlide,
    handleLightboxPrev,
    index,
    imagesCount,
    imageItem,
    lightBoxOpened,
    mainSrc,
    prevSrc,
    nextSrc
  } = useLightboxWrapper({
    items: lightboxItems,
    toggleBackdrop: togglePreventModalClose
  });

  const renderLightboxButtons = useCallback<LightboxRenderCustomButtons>(
    ({ index }) => {
      const selectedProduct = selectedProductsInLightbox[index];
      if (!selectedProduct) {
        return [];
      }

      return (
        <SelectedProductsListItemLightboxBottomButtons
          selectedProduct={selectedProduct}
          productsSet={productsSet}
          productsCacheKeys={productsCacheKeys}
          productsSetCacheKeys={productsSetCacheKeys}
          onSelectedProductsSidebarClose={onSelectedProductsSidebarClose}
          updateProductsSetCache={updateProductsSetCache}
        />
      );
    },
    [
      selectedProductsInLightbox,
      productsSet,
      productsCacheKeys,
      productsSetCacheKeys,
      onSelectedProductsSidebarClose,
      updateProductsSetCache
    ]
  );

  const renderImageTitle = useCallback<LightboxRenderImageTitle>(
    ({ index }) => {
      const selectedProduct = selectedProductsInLightbox[index];
      if (!selectedProduct) {
        return null;
      }

      return (
        <SelectedProductsListItemLightboxTitle
          selectedProduct={selectedProduct}
        />
      );
    },
    [selectedProductsInLightbox]
  );

  return (
    <Fragment>
      <AlertMessage addClassName="m-4" message={productsSetError} />
      <Loading loaded={!productsSetIsPlaceholderData} />
      <LoadingSkeleton
        loaded={productsSetIsPlaceholderData || productsSetFetched}
      >
        {selectedProductsTotalCount === 0 ? (
          <NoResults addErrorClassName="m-4" />
        ) : (
          <Fragment>
            {checkable && (
              <Checkbox
                checked={checkedAll}
                onChange={onCheckAll}
                i18nLabel={productsKeys.all}
                checkboxWrapperClassName="flex items-center mb-6"
                labelClassName="ml-2 block text-sm text-gray-900 dark:text-gray-100"
              />
            )}

            <ul className="space-y-4">
              {selectedProducts.map((selectedProduct) => (
                <SelectedProductsListItem
                  key={selectedProduct.uuid}
                  productsSet={productsSet}
                  selectedProduct={selectedProduct}
                  productsSetCacheKeys={productsSetCacheKeys}
                  onLightboxOpen={handleLightboxOpenOnSlide}
                  onSelectedProductsSidebarClose={
                    onSelectedProductsSidebarClose
                  }
                  updateProductsSetCache={updateProductsSetCache}
                  checkable={checkable}
                  checked={checkedHash?.[selectedProduct.id]}
                  onCheck={onSetCheckedIds}
                />
              ))}
            </ul>
          </Fragment>
        )}
      </LoadingSkeleton>

      <LightboxWrapper
        handleLightboxClose={handleLightboxClose}
        handleLightboxNext={handleLightboxNext}
        handleLightboxOpen={handleLightboxOpen}
        handleLightboxPrev={handleLightboxPrev}
        index={index}
        imagesCount={imagesCount}
        imageItem={imageItem}
        lightBoxOpened={lightBoxOpened}
        mainSrc={mainSrc}
        nextSrc={nextSrc}
        prevSrc={prevSrc}
        renderImageTitle={renderImageTitle}
        renderCustomButtons={renderLightboxButtons}
        withFullScreenButton
      />
    </Fragment>
  );
}

export default memo<SelectedProductsListProps>(SelectedProductsList);
