import React from 'react';

import { ClassName, I18nText } from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import {
  UpdateProductsSetCacheAction,
  FetchProductsSetsCacheKeys
} from '../../../../../../productsSets/productsSetsTypes';

import { OnSelectedProductsSidebarCloseAction } from '../../../../../../products/productsTypes';

import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';

import useSelectedProductsListItemDeleteButton from './useSelectedProductsListItemDeleteButton';

import {
  SelectedProductsListItemDeleteButtonSelectedProduct,
  SelectedProductsListItemDeleteButtonProductsSet
} from './SelectedProductsListItemDeleteButton.types';

interface SelectedProductsListItemDeleteButtonProps {
  selectedProduct: SelectedProductsListItemDeleteButtonSelectedProduct;
  productsSet: SelectedProductsListItemDeleteButtonProductsSet;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
  className?: ClassName;
  iconClassName?: ClassName;
  icon?: IconsEnum;
  i18nText?: I18nText;
  onSelectedProductsSidebarClose: OnSelectedProductsSidebarCloseAction;
  updateProductsSetCache: UpdateProductsSetCacheAction<SelectedProductsListItemDeleteButtonProductsSet>;
}

function SelectedProductsListItemDeleteButton({
  selectedProduct,
  productsSet,
  productsSetCacheKeys,
  className,
  iconClassName,
  icon,
  i18nText,
  onSelectedProductsSidebarClose,
  updateProductsSetCache
}: SelectedProductsListItemDeleteButtonProps) {
  const { handleToggleSelected } = useSelectedProductsListItemDeleteButton({
    selectedProduct,
    productsSet,
    productsSetCacheKeys,
    onSelectedProductsSidebarClose,
    updateProductsSetCache
  });

  return (
    <>
      <PureIconButtonHelper
        className={className}
        icon={icon}
        iconClassName={iconClassName}
        i18nText={i18nText}
        onClick={handleToggleSelected}
      />
    </>
  );
}

export default SelectedProductsListItemDeleteButton;
