import React, { useMemo } from 'react';

import { FilterBadgeProps } from '../../../../../helpers/filterBadges/types';

import {
  FETCH_PRODUCT_MATERIALS_QUERY,
  FetchProductMaterialsQueryResponse
} from '../../../queries/fetchProductMaterials.query';

import { useFinInfiniteCategories } from '../../../../categories/hooks/useFinInfiniteCategories';

import { ItemsFilterBadge } from '../../../../../helpers/filterBadges/ItemsFilterBadge';

import { categoriesKeys } from '../../../../../locales/keys';

import { ProductCache } from '../../../ProductCache';

import { FetchCategoriesScopes } from '../../../../categories/categoriesTypes';

interface ProductsMaterialsFilterBadgeItem {
  id: string;
  label: string;
}

function ProductsMaterialsFilterBadge({
  filters,
  name,
  onRemoveFilter
}: FilterBadgeProps) {
  const { categories: productMaterials } =
    useFinInfiniteCategories<FetchProductMaterialsQueryResponse>({
      cacheKey: ProductCache.materialsFilterBadgeCacheKey(),
      query: FETCH_PRODUCT_MATERIALS_QUERY,
      initialFilters: {
        scope: { eq: FetchCategoriesScopes.FACILITY_MATERIALS }
      }
    });

  const ProductsMaterialsFilterBadgeItems = useMemo<
    ProductsMaterialsFilterBadgeItem[]
  >(() => {
    return productMaterials.map((category) => ({
      id: category.id as string,
      label: category.localizedName
    }));
  }, [productMaterials]);

  return (
    <ItemsFilterBadge
      filters={filters}
      i18nLabel={categoriesKeys.materials.singular}
      items={ProductsMaterialsFilterBadgeItems}
      name={name}
      onRemoveFilter={onRemoveFilter}
    />
  );
}

export default ProductsMaterialsFilterBadge;
