import {
  FetchFinProductCategoriesFilters,
  FetchProductCategoriesCacheKey
} from '../../productsTypes';

import {
  FETCH_PRODUCT_SEASONAL_QUERY,
  FetchProductSeasonalQueryResponse
} from '../../queries/fetchProductSeasonal.query';

import { useFinPaginatedCategories } from '../../../categories/hooks/useFinPaginatedCategories';

import { FetchCategoriesScopes } from '../../../categories/categoriesTypes';

const defaultInitialFilters = {
  scope: { eq: FetchCategoriesScopes.PRODUCTS_SEASONAL }
};
const options = {
  staleTime: 1000 * 60 * 60
};

interface ProductSeasonalDefaultOptions {
  cacheKey: FetchProductCategoriesCacheKey;
  addInitialFilters?: FetchFinProductCategoriesFilters;
}

function useProductSeasonal({
  cacheKey,
  addInitialFilters
}: ProductSeasonalDefaultOptions) {
  const initialFilters = addInitialFilters
    ? { ...defaultInitialFilters, ...addInitialFilters }
    : defaultInitialFilters;

  const {
    categories,
    categoriesError,
    categoriesFetched,
    categoriesIsPlaceholderData
  } = useFinPaginatedCategories<FetchProductSeasonalQueryResponse>({
    cacheKey,
    query: FETCH_PRODUCT_SEASONAL_QUERY,
    initialFilters,
    options
  });

  return {
    productSeasonal: categories,
    productSeasonalErrorMessage: categoriesError,
    productSeasonalFetched: categoriesFetched,
    productSeasonalIsPlaceholderData: categoriesIsPlaceholderData
  };
}

export default useProductSeasonal;
