import { useCallback } from 'react';
import size from 'lodash/size';

import {
  UpdateProductsSetCacheAction,
  FetchProductsSetsCacheKey
} from '../../../productsSets/productsSetsTypes';

import { OnSelectedProductsSidebarCloseAction } from '../../../products/productsTypes';

import { useEmptyProductsSet } from '../../../productsSets/hooks/useEmptyProductsSet';

import { useShowToastOnErrorChange } from '../../../../common/hooks/useShowToastOnErrorChange';

import { ProductsSetCache } from '../../../productsSets/ProductsSetCache';

import {
  SelectedProductsFloatingCounterProductsSet,
  SelectedProductsFloatingCounterOnProductsSelect
} from './SelectedProductsFloatingCounter.types';

interface UseProductsIndexPageSelectedProductsOptions {
  productsSet: SelectedProductsFloatingCounterProductsSet;
  productsSetCacheKey?: FetchProductsSetsCacheKey;
  onClose: OnSelectedProductsSidebarCloseAction;
  updateProductsSetCache: UpdateProductsSetCacheAction<SelectedProductsFloatingCounterProductsSet>;
  onProductsSelect?: SelectedProductsFloatingCounterOnProductsSelect;
}

function useSelectedProductsFloatingCounter({
  productsSet,
  productsSetCacheKey,
  onClose,
  updateProductsSetCache,
  onProductsSelect
}: UseProductsIndexPageSelectedProductsOptions) {
  const uuid = productsSet?.uuid;

  const {
    emptyProductsSetLoading,
    emptyProductsSetErrorMessage,
    emptyProductsSet
  } = useEmptyProductsSet({
    itemCacheKey: productsSetCacheKey || ProductsSetCache.showCacheKey(),
    updateProductsSetCache
  });

  const handleEmptyProductsSet = useCallback(() => {
    uuid && emptyProductsSet({ uuid }).then(() => onProductsSelect?.([]));
    onClose();
  }, [uuid, emptyProductsSet, onClose, onProductsSelect]);

  useShowToastOnErrorChange({ error: emptyProductsSetErrorMessage });

  const selectedProductsCount = size(productsSet?.selectedProducts);

  return {
    selectedProductsCount,
    emptyProductsSetLoading,
    handleEmptyProductsSet
  };
}

export default useSelectedProductsFloatingCounter;
