import React from 'react';

import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

import { TextWithTooltip } from '../../../../../../../helpers/TextWithTooltip';
import { Translate } from '../../../../../../../helpers/Translate';

import { SelectedProductsListItemLightboxTitleSelectedProduct } from './SelectedProductsListItemLightboxTitle.types';

interface SelectedProductsListItemLightboxTitleProps {
  selectedProduct: SelectedProductsListItemLightboxTitleSelectedProduct;
}

function SelectedProductsListItemLightboxTitle({
  selectedProduct
}: SelectedProductsListItemLightboxTitleProps) {
  return (
    <div className="flex-1 overflow-hidden p-1.5 text-left">
      <div className="text-sm">
        <div className="text-white truncate">
          {selectedProduct.product?.blocked ? (
            <span className="bg-red-200 text-gray-800 px-1 py-0.5 text-xs rounded uppercase mr-1">
              <Translate id="forms.fields.blocked" />
            </span>
          ) : null}
          <TextWithTooltip
            text={selectedProduct.product?.name}
            className="font-medium"
            tooltipPlacement={TooltipPlacement.BOTTOM}
          />
        </div>
        <div className="text-gray-300 truncate">
          {selectedProduct.product?.nda ? (
            <span className="bg-yellow-200 text-gray-800 px-1 py-0.5 text-xs rounded uppercase mr-1">
              <Translate id="forms.fields.nda" />
            </span>
          ) : null}
          {selectedProduct.product?.brand ? (
            <TextWithTooltip
              text={selectedProduct.product?.brand?.localizedName}
              tooltipPlacement={TooltipPlacement.BOTTOM}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default SelectedProductsListItemLightboxTitle;
