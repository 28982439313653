import React, { Fragment, memo } from 'react';
import cl from 'classnames';

import {
  FetchProductsFilters,
  ProductsFiltersNavBasePath,
  ProductsCustomExitNavPath,
  ProductsI18nCustomBaseTitle,
  ChangeProductsFiltersFunc,
  FetchProductsTotalCount
} from '../../productsTypes';
import { IconsEnum } from '../../../../assets/icons/types';
import { IsDisabled } from '../../../../types';

import { useProductsIndexPageBreadcrumbs } from './hooks/useProductsIndexPageBreadcrumbs';

import { ProductsIndexPageBreadcrumbsItem } from './components/ProductsIndexPageBreadcrumbsItem';

import { NextPureLinkHelper } from '../../../../helpers/links/NextPureLinkHelper';
import { CheckPermissions } from '../../../../helpers/CheckPermissions';

import { ProductPath } from '../../ProductPath';
import { StockPath } from '../../../stock/StockPath';

import { getChangeProductsFiltersCategoriesData } from '../../utils/getChangeProductsFiltersCategoriesData';

import { ProductsPermissions } from '../../productsConstants';
import { productsKeys } from '../../../../locales/keys';

interface ProductsIndexPageBreadcrumbsProps {
  filtersNavBasePath?: ProductsFiltersNavBasePath;
  customExitNavPath?: ProductsCustomExitNavPath;
  i18nCustomBaseTitle?: ProductsI18nCustomBaseTitle;
  productsFilters: FetchProductsFilters;
  changeProductsFilters: ChangeProductsFiltersFunc;
  productsTotalCount: FetchProductsTotalCount;
  withoutStockLink?: boolean;
  disabled?: IsDisabled;
}

function ProductsIndexPageBreadcrumbs({
  filtersNavBasePath,
  customExitNavPath,
  i18nCustomBaseTitle,
  productsFilters,
  changeProductsFilters,
  productsTotalCount,
  withoutStockLink,
  disabled
}: ProductsIndexPageBreadcrumbsProps) {
  const {
    currentParentCategory,
    currentChildCategory,
    productCategories,
    handleResetCategoriesFilters,
    handleParentCategoryChangeFilters
  } = useProductsIndexPageBreadcrumbs({
    productsFilters,
    changeProductsFilters
  });

  return (
    <div className="flex-1 truncate flex pl-3 pr-1 h-full items-center text-base xl:text-lg text-gray-500 space-x-1.5">
      {!withoutStockLink && (
        <Fragment>
          <NextPureLinkHelper
            href={customExitNavPath || StockPath.products()}
            className={cl(
              'hover:underline',
              disabled ? 'pointer-events-none' : null
            )}
            icon={IconsEnum.CUBE_OUTLINE}
            iconClassName="h-6 w-6"
          />
          <span>/</span>
        </Fragment>
      )}

      <ProductsIndexPageBreadcrumbsItem
        href={filtersNavBasePath}
        active={!currentChildCategory && !currentParentCategory}
        i18nText={i18nCustomBaseTitle || productsKeys.plural}
        filtersNavBasePath={filtersNavBasePath}
        disabled={disabled}
        onChangeFilters={handleResetCategoriesFilters}
      />
      {currentParentCategory ? (
        <>
          {currentParentCategory.parent && (
            <>
              <span>/</span>
              <ProductsIndexPageBreadcrumbsItem
                href={
                  filtersNavBasePath
                    ? ProductPath.indexFiltersCustomBaseQuery(
                        filtersNavBasePath,
                        getChangeProductsFiltersCategoriesData({
                          productCategory: currentParentCategory.parent,
                          categories: productCategories
                        }).changeFilters
                      )
                    : '#'
                }
                active={false}
                text={currentParentCategory.parent.localizedName}
                filtersNavBasePath={filtersNavBasePath}
                disabled={disabled}
                onChangeFilters={handleParentCategoryChangeFilters}
              />
            </>
          )}

          <span>/</span>
          <ProductsIndexPageBreadcrumbsItem
            href={
              filtersNavBasePath
                ? ProductPath.indexFiltersCustomBaseQuery(
                    filtersNavBasePath,
                    getChangeProductsFiltersCategoriesData({
                      productCategory: currentParentCategory,
                      categories: productCategories
                    }).changeFilters
                  )
                : '#'
            }
            active={!currentChildCategory}
            text={currentParentCategory.localizedName}
            filtersNavBasePath={filtersNavBasePath}
            disabled={disabled}
            onChangeFilters={handleParentCategoryChangeFilters}
          />
        </>
      ) : null}

      {currentChildCategory ? (
        <>
          <span>/</span>
          <ProductsIndexPageBreadcrumbsItem
            active
            text={currentChildCategory.localizedName}
            filtersNavBasePath={filtersNavBasePath}
            disabled={disabled}
          />
        </>
      ) : null}

      {productsTotalCount ? (
        <CheckPermissions
          action={ProductsPermissions.READ_PRODUCTS_TOTAL_COUNT}
        >
          <span className="py-1 px-3 text-xs font-medium rounded-full bg-gray-100 dark:bg-gray-600 text-black dark:text-white leading-tight ml-2">
            {productsTotalCount}
          </span>
        </CheckPermissions>
      ) : null}
    </div>
  );
}

export default memo<ProductsIndexPageBreadcrumbsProps>(
  ProductsIndexPageBreadcrumbs
);
